<template>
  <v-row class="search-wrap">
    <v-col align="center" class="pb-0">
      <div class="section-title mb-3" v-if="!hideTitle">{{ $t(title) }}</div>
      <v-autocomplete
        :value="preselectedAuctionId"
        :items="auctionsToSelect"
        :label="$t('Select auction')"
        append-icon="fa-angle-down"
        class="select-input"
        @change="handleChange"
        hide-details
        item-value="id"
        :item-text="item => item.code"
        :menu-props="{'contentClass': 'auction-autocomplete'}"
        :single-line="!hideTitle"
        :no-data-text="$t('No data available')"
      >
        <template #item="{ item, on }">
          <v-list-item
            ripple
            v-on="on"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ showAuctionType ? getAuctionLabel(item) : item.code }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #append-item v-if="!hideCreateNew">
          <v-divider class="mb-2" />
          <v-list-item
            ripple
            @click="$emit('openCreateAuctionModal')"
          >
            <v-list-item-icon>
              <v-icon>fa-circle-plus</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span>{{ $t('Create new auction') }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { AuctionFlavor, AuctionStatusType, localFeatures } from '@/utils/constants'
import { mapActions, mapState } from 'pinia';
import useRootStore from '@/stores/rootStore';

export default {
  name: 'SearchBox',
  emits: ['onSelectAuction', 'openCreateAuctionModal'],
  props: {
    auctions: {
      type: Array,
      required: true
    },
    currentAuction: {
      type: Object
    },
    title: {
      type: String,
      default: function () {
        return this.$t('Select auction')
      }
    },
    hideCreateNew: {
      type: Boolean,
      default: false
    },
    showAuctionType: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    hideCompletedAuctions: {
      type: Boolean,
      default: false
    },
    orderByIdAscending: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showAuctionNumberOnSearchField: localFeatures.showAuctionNumberOnSearchField
  }),
  computed: {
    ...mapState(useRootStore,['preselectionData']),
    auctionsToSelect() {
      const sortedAuctions = [...this.auctions];
      sortedAuctions.sort((e1, e2) => this.orderByIdAscending ? e1.id - e2.id : e2.id - e1.id);
      if (this.hideCompletedAuctions) {
        return sortedAuctions.filter(e => {
          return (this.moment(e.active_until).diff(this.moment(), 'seconds') > 0 && e.catalogue_enabled !== 'disabled' && !(e.type === AuctionFlavor.live && e.status === AuctionStatusType.completed))
        })
      } else {
        return sortedAuctions;
      }
    },
    preselectedAuctionId() {
      return this.currentAuction ? this.currentAuction.id : null;
    }
  },
  methods: {
    ...mapActions(useRootStore,['UPDATE_GLOBAL_STATE']),
    handleChange (auctionID) {
      if (this.preselectionData.selectedAuctionId !== auctionID) {
        this.UPDATE_GLOBAL_STATE({
          key: 'preselectionData',
          value: {...this.preselectionData, selectedAuctionId: auctionID}
        })
      }

      this.$emit('onSelectAuction', auctionID)
    },

    /**
     * Add the auction name and type, i.e. "Sommerauktion (Live)"
     * @return {any} - Modified auctions
     */
    getAuctionLabel(auction) {
      let auctionType = ''
      if (auction.type === AuctionFlavor.live && auction.signup_enabled) {
        auctionType = this.$t('Live')
      } else if (auction.type === AuctionFlavor.timed) {
        auctionType = this.$t('Timed')
      } else if (auction.type === AuctionFlavor.sale) {
        auctionType = this.$t('Sale')
      }
      let text = this.showAuctionNumberOnSearchField ? `${auction.code} | ${auction.name}` : `${auction.name}`
      if (auctionType !== '') text += ` (${auctionType})`
      return text
    },
    // stringify: JSON.stringify,
  }
}
</script>
